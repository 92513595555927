import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
const Sign = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <div className="flex justify-center md:px-40 px-3 md:py-36 py-4">
        <div className="px-3 grid justify-center md:grid-cols-3 gap-12 grid-cols-1 container">
          <div
          data-aos="fade-right"
            className="rounded-lg shadow-2xl py-20"
            style={{ backgroundImage: `url('/images/oneblur.jpg')` }}
          >
            <h1 className=" text-[27px] py-2 px-3 text-white font-semibold text-center">
              Get started here!
            </h1>
            <h1 className=" font-extrabold text-[27px] text-center text-white">
              AGENTS click here to get setup!
            </h1>
            {/* <a href="https://agent.salesteamtracker.com/agent/register/ " target="_blank" rel="noreferrer">
             <button className=" rounded-lg button-down mt-4">
             Buy and Setup
              </button>
              </a> */}
           <div className="flex justify-center mt-2">
             <a href="/getstart "rel="noreferrer"><button className=" rounded-lg button-down mt-4">
           Buy and Setup
              </button>
              </a>
              </div>
              {/* https://agent.salesteamtracker.com/agent/register */}
          </div>
          <div
            className="rounded-lg shadow-2xl py-20"
            style={{ backgroundImage: `url('/images/twoblur.jpg')` }}
            data-aos="flip-right"
          >
            <h1 className=" text-[27px] py-2 px-3 text-white font-semibold text-center">
            Agent Login
            </h1>
            <h1 className=" font-extrabold text-[27px] text-center text-white">
            Existing customer? AGENTS login here!
            </h1>
            
           <div className="flex justify-center mt-2">  
              {/* <a href="https://agent.salesteamtracker.com/ " target="_blank" rel="noreferrer"> */}
              <a href="https://agent.barnyardtracker.com/ " target="_blank" rel="noreferrer">
           <button className=" rounded-lg button-down mt-4">
           Agent Login
              </button>
              </a>
              </div>
          </div>

          <div
          data-aos="fade-left"
            className="rounded-lg shadow-2xl py-14"
            style={{ backgroundImage: `url('/images/threeblur.jpg')` }}
          >
            <h1 className=" text-[27px] px-3 text-white font-semibold text-center">
            Aspirant/Team Member Login
            </h1>
            <h1 className=" font-extrabold text-[27px] text-center text-white">
            Rockstar producers<br/> login here!
            </h1>
           <div className="flex justify-center mt-2">
             {/* <a href="https://salesperson.salesteamtracker.com/ " target="_blank" rel="noreferrer"> */}
             <a href="https://salesperson.barnyardtracker.com/ " target="_blank" rel="noreferrer">
             <button className=" rounded-lg button-down mt-4 text-base">
             Aspirant/Team Member Login
              </button>
              </a>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sign;