import React, { useState, useEffect } from "react";
import axios from "axios";

const GetStarted = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    state: "",
    option: "",
    email: "",
    password: "",
    confirmpassword: "",
    phone: "",
    city: "",
  });

  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const options = ["Aspirant/Team Member", "Agent"]; // Options for the dropdown

  // Fetch States Dynamically
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get("https://sales-team.developer-iu.xyz/api/get/states");
        const { states } = response.data;
        setStates(states || []); // Handle if `states` is undefined
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    fetchStates();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required.";
    if (!formData.lastName) newErrors.lastName = "Last Name is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.option) newErrors.option = "Option is required.";
    if (!formData.email.endsWith("@statefarm.com")) {
      newErrors.email = "Email must match @statefarm.com";
    }
    if (!formData.password) newErrors.password = "Password is required.";
    if (formData.password !== formData.confirmpassword) {
      newErrors.confirmpassword = "Passwords do not match.";
    }
    if (formData.option === "Aspirant/Team Member") {
      if (!formData.phone) newErrors.phone = "Phone is required.";
      if (!formData.city) newErrors.city = "City is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    try {
      const submissionData = new FormData();
      Object.keys(formData).forEach((key) => {
        submissionData.append(key, formData[key]);
      });

      const response = await axios.post(`https://sales-team.developer-iu.xyz/api/${formData.option === "Aspirant/Team Member" ? "register/seller" : "register"}`, submissionData);
      const { message, status, inputErrors,payment_url } = response.data;
      console.log(response)
      alert(message); // Temporary alert for demo purposes
            window.location.href=payment_url

      
      if (status === "fails") {
        if (inputErrors?.email) setErrors((prev) => ({ ...prev, email: inputErrors.email }));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div style={{ backgroundImage: 'url(images/banner1.jpg)' }} className="bg-cover bg-center  flex items-center relative">
    <div className="get-started-container  mx-auto w-[500px] p-6  border rounded-lg shadow-lg bg-white p-4">
      <h2 className="text-2xl font-bold text-center mb-4">Get Started</h2>
      <p className="text-center text-gray-500 mb-6">Simple Immediate Access</p>
      <form onSubmit={handleSubmit} className="">
        {/* First Name */}
        <div className="mb-4">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          />
          {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>}
        </div>

        {/* Last Name */}
        <div className="mb-4">
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          />
          {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>}
        </div>

        {/* State Dropdown */}
      
        {/* Email */}
        <div className="mb-4">
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        {/* Password */}
        <div className="mb-4">
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          />
          {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
        </div>

        {/* Confirm Password */}
        <div className="mb-4">
          <input
            type="password"
            name="confirmpassword"
            placeholder="Confirm Password"
            value={formData.confirmpassword}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          />
          {errors.confirmpassword && <p className="text-red-500 text-sm mt-1">{errors.confirmpassword}</p>}
        </div>
        <div className="mb-4">
          <select
            name="state"
            value={formData.state}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state.id}>
                {state?.state}
              </option>
            ))}
          </select>
          {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state}</p>}
        </div>

        {/* Option Dropdown */}
        <div className="mb-4">
          <select
            name="option"
            value={formData.option}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          >
            <option value="">Select Role</option>
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors.option && <p className="text-red-500 text-sm mt-1">{errors.option}</p>}
        </div>
        { formData.option === "Aspirant/Team Member" && (
        <>
          
          
          <div className="mb-4  ">
          <input
            type="number"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
          />
          {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
          </div>
          <div  className="mb-4  ">

         
          <input
            type="text"
            name="city"
            placeholder="City"
            value={formData.city}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg"
            />
          {errors.city && <p className="text-red-500 text-sm mt-1">{errors.city}</p>}
            </div>
        </>
        )}
        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-primary text-white py-2 rounded-lg hover:bg-red-600"
        >
          Register
        </button>
      </form>
    </div>
    </div>
  );
};

export default GetStarted;
