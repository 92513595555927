
import { useEffect } from "react";

import Layout from "../Component/Layout/Layout"
import Sign from "../Component/Sign";


const Signin=()=>{
    useEffect(() => {
        window.scrollTo(0,0);
      }, []);
    return(
        <>
        <Layout>
           <Sign/>
         </Layout>
        </>
    )
}

export  default Signin